
.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Oswald";
  border-radius: 0;
  color: #6c7072;
}

.ant-table-wrapper table,
.ant-table-thead th {
  border-radius: 0;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-radius: 0;
}
.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-radius: 0;
}

.custom-table th,
.custom-table td {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 0.75rem;
  vertical-align: top;
  font-family: "Oswald";
  font-size: 1.04em;
}

.ant-table-cell, .ant-table-column-title, th {
  color: #6c7072 !important;
  padding-bottom: 0 !important;
}

.custom-table tbody tr:hover {
  background-color: #f5f5f5;
}

.notification-status-static {
  /* Adicione suas regras de estilo aqui. Exemplo: */
  background-color: #fff3cd;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-text-static {
  /* Adicione suas regras de estilo aqui. Exemplo: */
  font-size: 1em;
  color: #c1963a;
  font-family: "Oswald";
  margin: 0;
  padding: 0;
}

.notification-status {
  /* Adicione suas regras de estilo aqui. Exemplo: */
  background-color: #ccd3d8;
  border: 1px solid #6c7072;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.text-link{
  font-size: 1.1em;
  color: #c1963a;
  margin: 0;
  padding: 0;
  font-family: "Oswald";
}